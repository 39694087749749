/* public/static/css/Header.css */

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.logo {
  display: flex;
  align-items: center;
}

.logo img {
  height: 40px;
  margin-right: 10px;
}

.nav a {
  margin-left: 20px;
  text-decoration: none;
  color: #333;
  font-size: 16px;
}

.nav a:hover {
  color: #4CAF50;
}
.nav-buttons {
  display: flex;
  align-items: center;
  gap: 10px;
}

.explore-btn {
  background-color: white;
  color: #4285f4;
  border: 1px solid #4285f4;
  border-radius: 30px;
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
}

.balance {
  background-color: #f1f3f4;
  color: #333;
  padding: 10px 20px;
  border-radius: 5px;
  font-weight: bold;
}

.add-btn {
  background-color: #4285f4;
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.profile-btn {
  background-color: white;
  color: #333;
  border: 1px solid #ddd;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

