@import '../../fonts/poppinsfonts.css';

:root {
    --ep-font-poppins: "Poppins", sans-serif;
    --ep-body-color: #74748A;
    --ep-primary-color: #2967F6;
    --ep-secondary-color: #1D1D1D;
    --ep-bodyColor: #F9F9F9;
    --ep-fontSize14: 14px;
}
body{
    font-family: var(--ep-font-poppins);
    font-weight: 400;
    color: var(--ep-body-color);
    font-size: 14px;
    background-color: var(--ep-bodyColor);
}
svg{
    vertical-align: middle;
    display: inline-block;
}
h1, h2, h3, h4, h5, h6{
    color: var(--ep-secondary-color);
}
p{
    margin: 0;
}
.text-danger {
    color: #DB3536;
}
.btn-primary {
    border-radius: 8px;
    background-color: var(--ep-primary-color);
}
.btn.disabled, .btn:disabled{
    background: rgba(41, 103, 246, 0.20);
    border-color: rgb(226 235 254);
}
.navbar-toggler{
    width: 40px;
    height: 40px;
    padding: 0;
}
a{
    text-decoration: none;
    color: var( --ep-primary-color);
}
.btn-outline-primary{
    border: 1px solid var(--ep-primary-color);
    background: transparent;
    color: var(--ep-primary-color);
    transition: background-color 0.3s ease;
}
ul, ol{
    padding: 0;
    margin: 0;
    list-style: none;
}

.wrapper {
    display: grid;
    position: relative;
    width: 100%;
    height: 100%;
    grid-template-areas:
    "header"
    "main";
}
header{
    grid-area: header;
}
.site-main{
    grid-area: main;
    padding: 30px 10px 0px;
}
.price {
    border-radius: 8px;
    background: rgba(41, 103, 246, 0.12);
    display: flex;
    padding: 4px;
    justify-content: center;
    align-items: center;
    color: var(--ep-secondary-color);
    line-height: 24px;
}
.profile-btn{
    width: 44px;
    height: 44px;
    border: 1.5px solid var(--ep-primary-color);
    background: #F5F8FF;
    color: var(--ep-secondary-color);
    text-align: center;
    line-height: 24px; 
}
.profile-btn::after{
    display: none;
}
.profile_name {
    border-radius: 50px;
    border: 1.5px solid #2967F6;
    background: #F5F8FF;
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    color: var(--secondary-color);
    text-align: center;
    font-size: clamp(14px, 2.0vw, 16px);
    font-weight: 500;
    line-height: 24px;
    width: 35px;
    height: 35px;
    overflow: hidden;
}
.profileMenu.show > ul {
    opacity: 1;
    transform: translateY(0%);
}
.user-email {
    color: #74748A;
    font-size: 14px;
    font-weight: 400;
}
.navbar .nav-link {
    font-size: 14px;
    border-radius: 4px;
    color: var(--ep-secondary-color);
}
.navbar .nav-link:hover {
    color: rgb(41, 103, 246);
    background: rgb(234, 240, 254);
}
.price > .btn {
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.aside-card {
    border: 1px solid #E7E7F1;
    border-radius: 12px;
}
.listed h2 {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    margin-bottom: 0;
    color: var(--ep-body-color);
}
.listed h1{
    font-size: clamp(18px, 2.0vw, 24px);
    font-weight: 700;
    line-height: 32px; 
    margin-bottom: 0;
}
.aside-card h3{
    font-size: 1rem;
}
.step-icon {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #fff;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    border-style: solid;
    border-width: 1.5px;
    position: relative;
    border-color: var(--ep-body-color);
}
.completed .step-icon {
    background-color: var( --ep-primary-color);
    border-color: var(--ep-primary-color);
}
.aside-card .active .step-icon::before {
    width: 100%;
    height: 100%;
    background: var(--ep-primary-color);
    border-radius: 100%;
    left: 0;
    top: 0;
    border: 1px solid #fff;
}
.aside-card .active .step-icon.warning::before, .aside-card .active .step-icon.danger::before {
    background: transparent;
}
.aside-card p{
    margin-bottom: 0;
}
.aside-card .active{
    background-color: #F1F1FA;
}
.aside-card .warning{
    border-color: #EC982A;
    background-color: #EC982A;
}
.aside-card .danger{
    background: #EC4E2A;
    border-color: #EC4E2A;
}

.model-card {
    display: flex;
    padding: 12px;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    border-radius: 12px;
    border: 1px solid #E7E7F1;
    background: #E7E7F1;
    margin-bottom: 1.5rem;
}
.model-info h3 {
    font-size: 16px;
    margin-bottom: 5px;
    color: #1D1D1D;
}
.model-info p {
    font-size: clamp(12px, 2.0vw, 13px);
    line-height: 1.4;
}
.model-image {
    width: 100px;
    height: auto;
    border-radius: 4px;
    object-fit: cover;
    object-position: center;
}
.add-funds-wrap {
    border: 1px solid #E7E7F1;
    border-radius: 12px;
    background-color: #fff;
}
.add-info h3{
    font-size: 18px;
    font-weight: 600;
}
.alert-warning {
    border-radius: 4px;
    background: rgba(236, 152, 42, 0.10);
    border-color: rgba(236, 152, 42, 0.10);
}
.alert-warning > svg {
    flex-shrink: 0;
}
.alert-contnent h3 {
    color: #EC982A;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
}
.alert-contnent p {
    color: #494F58;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
}
.usd-info {
    display: flex;
    padding: 14px 16px;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    border-radius: 5px;
    border: 1.2px solid #E7E7F1;
    background: #F7F7F7;
    width: 100%;
}

.usd-info > span {
    overflow: hidden;
    color: #1D1D1D;
    text-align: center;
    text-overflow: ellipsis;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    flex: 0 0 auto;
}
.usd-info > span > span {
    color: #9A9A9A;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}
.usd-info .form-control {
    padding: 0;
    background: transparent;
    outline: none;
    border: 0;
    font-size: 14px;
}
.usd-info .form-control:focus{
    box-shadow: none;
}
.recharge-wrap h2, .recently-view h2 {
    color: var(--ep-body-color);
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
}
.tabs-listed {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(129px, 1fr));
}
.tabs-listed li {
    display: flex;
    height: 100%;
    padding: 20px 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2px;
    flex: 1 0 0;
    cursor: pointer;
    border-radius: 8px;
    border: 1.4px solid #E7E7F1;
}
.tabs-listed li:hover, .tabs-listed li.active{
    border-radius: 8px;
    border: 1.4px solid var(--ep-primary-color);
    background: #EAF0FE;
}
.tabs-listed li > h4{
    color:  var(--ep-secondary-color);
    text-align: center;
    font-size: clamp(16px, 2.0vw, 20px);
    margin-bottom: 0;
    font-weight: 500;
    line-height: normal;
}
.tabs-listed li  > span{
    color: var(--ep-body-color);
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
}
.billing-info {
    display: flex;
    padding: 10px 16px;
    justify-content: start;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 6px;
    background: #EAF0FE;
}

.billing-info p {
    color: var(--ep-secondary-color);
    font-size: clamp(12px, 2.0vw, 14px);
    font-weight: 400;
    line-height: 20px;
}
.billing-info p > a {
    color: var(--ep-primary-color);
}
.tab-content label{
    color: var(--ep-body-color);
    font-size: clamp(12px, 2.0vw, 14px);
    padding-left: .5rem;
}
.form-check-input{
    width: 1.5em;
    height: 1.5em;
}
.step-icon::before {
    display: block;
    width: 7px;
    height: 14px;
    border: solid #ffffff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    top: 2px;
    left: 7px;
    position: absolute;
    content: "";
    border-radius: 1px;
}

.dropdown {
    position: relative;
    width: 100%;
}
.dropdown-selected {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    border-radius: 5px;
    border: 1.2px solid #E7E7F1;
    background: #F7F7F7;
    padding: 12px 12px 12px 12px;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    overflow: hidden;
    color: #74748A;
    text-overflow: ellipsis;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}
.dropdown.active .dropdown-selected {
    border-color: var(--ep-primary-color);
}
.dropdown-list {
    position: absolute;
    width: 100%;
    display: none;
    max-height: 200px;
    overflow-y: auto;
    border-radius: 5px;
    border: 1.2px solid #E7E7F1;
    background: #FFF;
    padding: 8px;
    overscroll-behavior-y: contain;
    scroll-behavior: smooth;
    scrollbar-width: auto;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.06);
}
.dropdown-item {
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
    position: relative;
}
.dropdown-selected svg {
    transition: transform 0.3s ease;
}
.dropdown.active .dropdown-selected svg {
    transform: rotate(180deg);
}
.dropdown-item:hover, .dropdown-item.active {
    background-color: #F1F1FA;
    color: var(--ep-primary-color);
}
.dropdown-item::after {
    content: "";
    display: block;
    width: 7px;
    height: 14px;
    border: solid var(--ep-primary-color);
    border-width: 0 2px 2px 0;
    transform: rotate(45deg) translateY(-50%);
    -webkit-transform: rotate(45deg) translateY(-42%);
    top: 42%;
    right: 20px;
    position: absolute;
    content: "";
    border-radius: 1px;
    opacity: 0;
    visibility: hidden;
    transition: all .2s ease;
}
.dropdown-item.active::after, .dropdown-item:hover::after {
    opacity: 1;
    visibility: visible;
}
.dropdown.active .dropdown-list {
  display: block;
}

.scrollheight::-webkit-scrollbar, 
.tab-content .tab-pane pre::-webkit-scrollbar {
    width: 5px;
}

.scrollheight::-webkit-scrollbar-track, 
.tab-content .tab-pane pre::-webkit-scrollbar-track  {
    background: #D9D9D9;
    border-radius: 10px;
}

.scrollheight::-webkit-scrollbar-thumb,
.tab-content .tab-pane pre::-webkit-scrollbar-thumb  {
    background: var(--ep-primary-color);
    border-radius: 10px;
}

.scrollheight::-webkit-scrollbar-thumb:hover,
.tab-content .tab-pane pre::-webkit-scrollbar-thumb:hover  {
    background: #419DFD;
}

.aside-card h4{
    font-size: 14px;
}
.primarykey {
    display: flex;
    padding: 6px 12px;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    border-radius: 5px;
    border: 1.2px solid #E7E7F1;
    background: #F7F7F7;
    justify-content: space-between;
}
.primarykey .btn {
    padding: 0;
    width: 35px;
    border: 0;
    height: 35px;
    color: #1d1d1d;
    background-color: transparent;
}
.primarykey .btn:active, .primarykey .btn:focus-visible{
    border: 0;
    box-shadow: none;
}
.primarykey> span {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    flex: 1 0 0;
    overflow: hidden;
    color: #74748A;
    text-overflow: ellipsis;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}
.primarykey .btn:hover {
    color: var(--ep-primary-color);
}
.form-control{
    font-size: 14px;
}
.form-control:focus{
    box-shadow: none;
}
.aside-card .nav-pills .nav-link {
    background-color: transparent;
    color: #74748A;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: -3px;    
    padding: 0;
    padding-bottom: 6px;
    border-radius: 0;
    border-bottom-style: solid;
    border-bottom-color: transparent;
    border-bottom-width: 3px;
}
.aside-card .nav-pills .nav-link.active, 
.aside-card .nav-pills .nav-link:hover {
    color: var(--ep-primary-color);
    font-weight: 500;
    border-bottom-color: var(--ep-primary-color);
}
.nav-pills{
    border-bottom: 3px solid #EBEEF7;
}
.tab-content .tab-pane pre{
    overflow-y: auto;
    page-break-after: auto;
    font-size: 14px;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    display: flex;
    height: 265px;
    padding: 11px 4px 11px 12px;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    border-radius: 5px;
    border: 1.2px solid #E7E7F1;
    background: #F7F7F7;
    overscroll-behavior: contain;
    scroll-behavior: smooth;
    scrollbar-width: auto;
    margin-bottom: 0;
    word-break: break-word;
}


.card-recently {
    border-radius: 12px;
    border: 1px solid #E7E7F1;
}
.recently-view h2 {
    color: var(--ep-secondary-color);
}
.card-recently .card-body h3{
    font-size: 14px;
    color: var(--ep-secondary-color);
    font-weight: 600;
}
.card-recently .card-body p{
    color: var(--ep-body-color);
    font-size: 12px;
    line-height: 18px; 
    margin-bottom: 0;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.fs-14{
    font-size: var(--ep-fontSize14);
}
.card-recently img{
    object-position: center;
    height: 90px;
}
.text-bg-success {
    border-radius: 10px;
    background-color: #54A655 !important;
    box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.15);
    width: 290px;
    right: 20px;
    top: 70px;
    text-align: center;
    font-size: 13px;
}
.text-bg-danger{
    background-color: #A65454 !important;
    text-align: start;
}
.text-bg-danger small{
    font-size: 8px;
}
.text-bg-danger small a{
    color: #92A8DC;
}
.scrollheightView {
    max-height: calc(100vh - 20vh);
    overflow-y: auto;
    scrollbar-width: auto;
    scroll-behavior: smooth;
    scroll-padding-right: 10px;
    padding-right: 5px;
    scroll-margin-right: 10px;
}
.scrollheightView::-webkit-scrollbar {
    width: 5px;
}
.scrollheightView::-webkit-scrollbar-track {
    background: #D9D9D9;
    border-radius: 10px;
}
.scrollheightView::-webkit-scrollbar-thumb {
    background: #fff;
    border-radius: 10px;
}
.scrollheightView::-webkit-scrollbar-thumb:hover {
    background: #419DFD;
}
/* loader css start */

.skeleton-wrapper {
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    background-color: #fff;
    padding: 24px;
    border: 1px solid #E7E7F1;
    border-radius: 12px;
}

.skeleton {
    background: linear-gradient(90deg, #E8ECFF 25%, #F9F9F9 50%, #E8ECFF 75%);
    background-size: 200% 100%;
    animation: shimmer 2.5s infinite linear;
    border-radius: 4px;
}

.skeleton-header {
    height: 16px;
    width: 100%;
}

.skeleton-line {
    height: 16px;
    width: 100%;
}

.skeleton-box {
    height: 270px;
    width: 100%;
}

@keyframes shimmer {
    0% { background-position: 200% 0; }
    100% { background-position: -200% 0; }
}
.object-fit-cover{
    object-fit: cover;
}
.vr {
    display: inline-block;
    align-self: stretch;
    width: 1px;
    min-height: 1em;
    background-color: currentcolor;
    opacity: .25;
}
/* css end */

/* =================================== 
    Media start 
  ============================== */

@media(max-width: 1399px) {
    .aside-card p{
        font-size: 12px;
    }
}

@media (max-width: 767px) {
    .navbar-brand > img{
        width: 170px;
        height: auto;
    }
    .overflow-xl-hidden{
        overflow: hidden;
    }
    .profileMenu{
        position: fixed;
        bottom: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.50);
        padding: 0;
        margin: 0;
        border-radius: 0;
        border: 0;
        display: block;
        opacity: 0;
        visibility: hidden;
        -webkit-transition: opacity .15s linear;
        transition: opacity .15s linear;
    }
    .profileMenu > ul{
        list-style: none;
        background: #fff;
        bottom: 0;
        left: 0;
        position: absolute;
        width: 100%;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        padding: 1rem;
        max-height: 500px;
        overflow-y: auto;
        scroll-behavior: smooth;
        scrollbar-width: none;
        overscroll-behavior: contain;
        transform: translateY(100%);
        opacity: 0;
        transition: transform 0.3s ease, opacity 0.3s ease;
    }
    .profileMenu.show{
        opacity: 1;
        visibility: visible;
    }
    
    .gap-4 {
        gap: 1rem !important;
    }
    .p-4 {
        padding: 1rem !important;
    }
    .site-main{
        padding: 16px 0px;
    }
    .navbar-collapse {
        position: absolute;
        top: 100%;
        left: 0;
        background: #fff;
        width: 100%;
        padding: 1rem;
    }
    .price span{
        font-size: 12px;
    }
    .api-link {
        font-size: 16px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        width: 100%;
        border-radius: 8px;
        background:  #DCEAFF;
    }
    .profile-btn {
        width: 36px;
        height: 36px;
        font-size: 12px;
        overflow: hidden;
    }
    .price > .btn{
        width: 30px;
        height: 30px;
    }
    .listed img{
        width: 50px;
        height: auto;
    }
    .scrollheightView{
        max-height: unset;
        padding-right: 0;
    }
    .tabs-listed li{
        padding: 10px 10px;
    }

}



