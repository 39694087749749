/* PaymentSuccess.css */

.payment-success {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: #fff;
    color: #2c742f;
    flex-direction: column
  }
  
  .success-content {
    text-align: center;
    font-size: 2em;
  }
  
  .star-icon {
    font-size: 1.5em;
  }

  .gotobutton {
    padding: 10px 20px;
    margin: 20px 0;
    font-size: 1.2em;
    cursor: pointer;
    background-color: #4285f4;
    border: none;
    border-radius: 5px;
    color: white;
  
  }
  
  .gotobutton:hover {
    background-color: #e76f51;
  }
  
 